<template>
  <div class="skeleton-profile">
    <skeleton-preloader-profile-layout>
      <template #desktop>
        <div class="skeleton-profile__form">
          <div
            class="skeleton-profile__form__item"
            v-for="n in FORM_INPUTS_COUNT"
            :key="n"
          >
            <Skeletor class="skeleton-profile__form__label" as="div" />
            <Skeletor class="skeleton-profile__form__input" as="div" />
          </div>
        </div>


        <div
          v-for="m in GAMES_GROUPS"
          :key="m"
          class="recently-played"
        >
          <div class="recently-played-header">
            <Skeletor class="title" as="div" />
            <Skeletor class="show-all" as="div" />
          </div>
          <div class="games">
            <Skeletor
              v-for="n in GAMES_COUNT"
              :key="n"
              class="game"
            />
          </div>
        </div>
      </template>

      <template #mobile>
        <Skeletor class="skeleton-profile__info" as="div" />
        <Skeletor
          v-for="n in MENU_POINTS_COUNT"
          :key="n"
          class="skeleton-profile__menu-point"
          as="div" />
      </template>
    </skeleton-preloader-profile-layout>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';

  const GAMES_GROUPS = 2;
  const GAMES_COUNT = 9;
  const MENU_POINTS_COUNT = 9;
  const FORM_INPUTS_COUNT = 3;
</script>

<style src="~/assets/styles/components/skeleton-preloader/profile-info-page.scss" lang="scss" />
